// color variants
@import './themes-vars.module.scss';

// third-party
@import 'react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

.MuiTextField-root {
  .Mui-disabled {
    background-color: #f4f4f4 !important;
  }
}

.MuiOutlinedInput-input,
.MuiOutlinedInput-root {
  background-color: #ffffff !important;
  .Mui-disabled {
    background-color: #f4f4f4 !important;
  }
  .MuiFormHelperText-root {
    background-color: white !important;
  }
}

.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  padding: 4px 4px 4px 8px !important;
}

.MuiDialogTitle-root {
  background-color: #f0f8ff;
  font-size: 16px;
  font-weight: 700;
}

.MuiDialog-paper {
  border-radius: 12px;
}

#table-preview-order {
  // border: 1px solid black;
  // border-collapse: collapse;

  th,
  td {
    // border: 1px solid black;
    // border-collapse: collapse;
    text-align: left;
    padding: 10px;
  }

  thead {
    th {
      min-height: 32px;
      text-align: right;
      border-bottom: 1px solid #E9E9E9;
      border-collapse: collapse;
    }
  }

  tbody {
    .group-row {
      border: 1px solid #E9E9E9;
      border-collapse: collapse;
      vertical-align: top;
      width: 70px;
    }

    .borderTop {
      border-top: 1px solid #E9E9E9;
      border-collapse: collapse;
    }

    .borderBottom {
      border-bottom: 1px solid #E9E9E9;
      border-collapse: collapse;
    }

    tr {
      min-height: 30px !important;
    }

    // .total-row {
    //   font-weight: bold;
    //   background-color: #e6e6e6;
    // }
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }
}
